<template >
  <div class="con" v-if="!$store.state.pcshow">
    <!-- 横幅图 -->
    <img src="../../assets/img/structure/img(2).png" alt="" />
    <div class="btngroup">
      <router-link to="/companyIntro" :class="$route.path === '/companyIntro'? 'active': ''">
        <div class="companyIntro">
          <p>集团简介</p>
        </div>
      </router-link>
      <router-link to="/structure"  :class="$route.path === '/structure'? 'active': ''">
        <div class="structure">组织架构</div>
      </router-link>
    </div>
    <div class="yj">
      <h4><span></span>使命愿景</h4>
      <img src="../../assets/img/index/companyIntro2.png" alt="">
      <img src="../../assets/img/index/companyIntro3.png" alt="">
      <img src="../../assets/img/index/companyIntro4.png" alt="">
    </div>
    <div class="yj2">
      <h4><span></span>集团业务</h4>
      <div class="imggroup clearfix">
        <div class="leftimg">
          <div class="lefttop clearfix">
            <router-link to="/investment">
              <img src="../../assets/img/index/companyIntro5.png" alt="">
            </router-link>
            <router-link to="/Incubator">
              <img src="../../assets/img/index/companyIntro6.png" alt="">
            </router-link>
          </div>
          <router-link to="/medicalcare">
            <img src="../../assets/img/index/companyIntro8.png" alt="">
          </router-link>
        </div>
        <div class="rightimg">
            <router-link to="/wj">
              <img src="../../assets/img/index/companyIntro7.png" alt="">
            </router-link>
            <router-link to="/healthy">
              <img src="../../assets/img/index/companyIntro9.png" alt="">
            </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- 集团简介 -->
  <div class="main" v-else>
    <!-- 横幅图 -->
    <img src="../../assets/img/structure/img(2).png" alt="" />
    <div class="mainContent">
      <!-- 组织架构图 -->
      <div class="structContent">
        <!-- 左侧导航 -->
        <div class="navLeft">
          <div class="comeTop">
            <div class="comeYiLian">
              <h2>走进亿联体</h2>
              <h4>About ENITED</h4>
              <div class="img">
                <img src="../../assets/img/structure/img(3).png" alt="" />
              </div>
            </div>
            <div class="blankArea"></div>
          </div>
          <div class="comeBottom">
            <ul>
              <li
                :class="
                  $route.path === '/companyIntro'
                    ? 'currentActive'
                    : 'defaultActive'
                "
              >
                <div class="borderLeft"></div>
                <router-link to="/companyIntro">
                  <div class="contentRight">
                    <p>集团简介</p>
                  </div>
                </router-link>
              </li>
              <li>
                <div class="borderLeft"></div>
                <router-link to="/structure">
                  <div class="contentRight">组织架构</div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-- 右边架构 -->
        <div class="navRight">
          <ul>
            <li>
              <div class="title"><h4>亿联体集团</h4></div>
              <div class="content">
                <p>
                  上海亿联体创业孵化器管理有限公司（简称亿联体集团）创立于2018年，是一家致力于通过健康产业投资布局，高新科技企业孵化帮扶，文教创新，整合全国医疗健康垂直领域优质资源等方式，打造多元化全产业链的投资商业集团。
                </p>
              </div>
            </li>
            <li>
              <div class="title"><h4>产业投资</h4></div>
              <div class="content">
                <p>
                  集团投资重点布局互联网医疗、健康管理咨询、医疗产品研发生产、综合医美服务、功能营养食品等投资领域，打造大健康全产业链投资
                  平台和专业化产业服务平台。集团紧跟国家发展战略与规划，努力将以区域为单位的行业生态圈打造成全国医疗联合体，实现医疗资源领
                  域的国民共同富裕。
                </p>
                <p>
                  目前集团与多家银行，投融资机构达成深度合作，并成功投资了中鑫广汇医疗科技（上海）有限公司等企业，两
                  家公司在2年内均已成为资产规模千万级别、利润规模稳定在5000万元左右的医疗健康行业中的优质企业。
                </p>
              </div>
            </li>
            <li>
              <div class="title"><h4>孵化器平台</h4></div>
              <div class="content">
                <p>
                  目前亿联体集团拥有孵化器产业园区16个，立足大健康相关领域全产业链资源对园区内在孵企业进行创业帮扶，吸引了大量的医疗健康企业
                </p>
                <p>
                  北京亿联祥和独角兽企业管理有限公司成立于2019年，由亿联体集团联合北京祥辉投资管理有限公司与中关村跨界创新联盟共同发起设立
                  的独角兽孵化平台企业。该平台结合自身科技优势与亿联体集团资源优势以国家制度意志为体现，建立亿元级别投资储备资金池，运用完
                  善的系统孵化流程，在一年内成功为24家企业进行帮扶并入驻平台基地，并先后为园区孵化企业进行项目投融资达4000万元。
                </p>
              </div>
            </li>
            <li>
              <div class="title"><h4>文教创新</h4></div>
              <div class="content">
                <p>定位-搭建校企优质人才教培桥梁，满足行业多元化用人需求。</p>
                <p>
                  集团以职业教育作为文教创新业务支点，推动大健康行业的人才创新、体系传承与可持续发展。集团整合优质行业企业资源与师资资源，
                  完善教培机制，加强校企合作和专业共建，积极调动企业参与程度，打造联合招生，联合培养，联合用工的新型培养模式。
                </p>
              </div>
            </li>
            <li>
              <div class="title"><h4>医疗联合体</h4></div>
              <div class="content">
                <p>
                  集团通过控股孵化器平台，投资医疗健康企业，组建医生集团、布局国际贸易、开展互联网大数据医疗产业、等多个业务线将行业资源通
                  过产业链条进行打通，合理配置资源，结合自身优势，完成由地方链接城镇，国内链接国际，科技结合产业的跨地区，跨国界的大健康领
                  域的横纵联合。目前旗下企业已与恒瑞集团、天江集团、京煤集团、北京同仁堂、北京中医药大学，天津大学、韩国医疗美容协会等国内
                  外知名机构院校开展多项项目合作，运用自主研发系统（PBM、健康教育平台等）与全国各园区作为通路将转化成果、优质资源迅速向基
                  层城市延伸部署，快速构建可孕育的医疗联合体雏形。
                </p>
              </div>
            </li>
            <li>
              <div class="title"><h4>生命健康群平台</h4></div>
              <div class="content">
                <p>
                  集团于今年二月与连云港经济技术开发区签订了生命健康集群平台的投资项目。该项目通过整体运营服务，数字化服务创新，行业资源导
                  入，产教研销四维一体全产业链生态搭建、金融配套服务等核心要素，为医药企业提供科学、系统的企业孵化服务，项目达产后年营业收
                  入15亿元，税收6000万元人民币。项目前期总投资1600万元人民币。
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="stylus" >
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem
        height:3rem
      }

      .btngroup{
        width: 7.5rem;
        height: 1.3rem;
        padding-left:0.35rem;

        a{
          display: block;
          width: 1.68rem;
          height: 0.68rem;
          background: #FFFFFF;
          float: left
          margin-top: 0.3rem;
          margin-right: 0.42rem;
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.68rem;
          text-align: center; 
        }
        a.active{
          background: #0085D0;
          color: #ffffff;
        }
      }
      .yj{
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.15rem;

        h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.35rem;
          padding-bottom:0.35rem;

          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }

        }

        >img{
          display: block;
          width: 6.8rem;
          height: 1.2rem;
          margin-bottom: 0.2rem;
        } 
      }
      .yj2{
        display: block;
        width: calc( 100% - 0.35rem );
        background: #ffffff;
        padding-left:0.35rem;
        padding-bottom:0.39rem;
        margin-top: 0.4rem;
        border-bottom: 0.57rem solid #f5f5f5;

        h4{
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          line-height: 0.39rem;
          padding-top:0.35rem;
          padding-bottom:0.35rem;

          span{
            display: inline-block;
            width: 0.03rem;
            height: 0.23rem;
            background: #0085D0;
            margin-right:0.07rem;
          }

        }

        >img{
          display: block;
          width: 6.8rem;
          height: 1.2rem;
          margin-bottom: 0.2rem;
        }
        
        .imggroup{
          width:6.8rem;

          .leftimg{
            width: 4.59rem;
            float:left;
            
            .lefttop{
              display: block;
              
              a{
                display: block;
                width:2.25rem;
                height:1.34rem;
                margin-bottom:0.09rem;
                float: left;

                img{
                  display: block;
                  width:2.25rem;
                  height:1.34rem;
                }
              }
              :nth-child(1){
                margin-right: 0.09rem;
              }
              
            }
            >a,>a>img{
              display: block;
              width:4.59rem;
              height: 2.78rem;
            }

          }
          .rightimg{
            display: block;
            float:right;

            a:nth-child(1){
              display: block;
              width:2.11rem;
              height: 2.79rem;
              margin-bottom:0.07rem;

              img{
                display: block;
                width:2.11rem;
                height: 2.79rem;
              }  
            }

            a:nth-child(2){
              display: block;
              width:2.11rem;
              height: 1.35rem;
              img{
                display: block;
                width:2.11rem;
                height: 1.35rem;
              }
            }

          }
        }
      }

    }
  }
  @media screen and (min-width: 960px) { 
    .main {
      margin-top: 100px;
      font-family: Source Han Sans CN;
      color: #333333;

      img {
        width: 100%;
      }

      .mainContent {
        width: 1280px;
        margin: 0 auto;

        .structContent {
          margin: 40px 0 120px;
          display: flex;
          justify-content: space-between;

          .navLeft {
            .comeTop {
              .comeYiLian {
                width: 280px;
                height: 118px;
                background: #0085D0;
                position: relative;

                h2 {
                  height: 29px;
                  font-size: 30px;
                  color: #FFFFFF;
                  font-weight: bold;
                  line-height: 39px;
                  padding: 33px 0 0 43px;
                }

                h4 {
                  height: 14px;
                  font-size: 16px;
                  font-weight: bold;
                  color: #FFFEFE;
                  line-height: 39px;
                  opacity: 0.5;
                  margin: 10px 0 0 43px;
                }

                .img {
                  position: absolute;
                  left: 188px;
                  top: 35px;
                  width: 90px;
                  height: 76px;
                }
              }

              .blankArea {
                width: 280px;
                height: 24px;
                background: #F5F5F5;
                border-bottom: 1px solid #0085D0;
              }
            }

            .comeBottom {
              ul {
                li {
                  height: 61px;
                  display: flex;
                  align-items: center;
                  padding: 0 0 0 40px;
                  border-bottom: 1px solid #0085D0;

                  .borderLeft {
                    width: 5px;
                    height: 15px;
                    background: #0085D0;
                  }

                  .contentRight {
                    margin-left: 14px;
                    width: 64px;
                    font-size: 16px;
                    font-weight: 500;
                  }
                }

                .currentActive {
                  background-color: #0085D0;

                  p {
                    color: #fff;
                  }

                  .borderLeft {
                    background-color: #fff;
                  }
                }
              }
            }
          }

          .navRight {
            padding-left: 40px;

            ul {
              li {
                .title {
                  padding-bottom: 10px;
                  border-bottom: 1px solid #EAEAEA;

                  h4 {
                    font-size: 26px;
                    font-weight: 500;
                    color: #0085D0;
                  }
                }

                .content {
                  padding-bottom: 100px;

                  p {
                    box-sizing: border-box;
                    margin-top: 24px;
                    margin-bottom: 40px;
                    width: 963px;
                    height: 46px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 30px;
                  }
                }

                &:nth-child(2) {
                  p:nth-child(1) {
                    margin-bottom: 80px;
                  }
                }

                &:nth-child(4) {
                  p:nth-child(1) {
                    margin-bottom: 0;
                  }
                }

                &:nth-child(5) {
                  padding-bottom: 80px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>